.logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: var(--foreground);
}

.logo > span {
    transform: rotate(180deg);
    padding-right: 5px;
}
