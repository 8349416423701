.tile {
    width: 100%;
    height: 20dvw;
    max-width: 100px;
    max-height: 100px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: min(4dvw, 18px);
    outline: 1px solid var(--black);
    background-color: var(--white);
    color: var(--black);
}

.good {
    background-color: var(--green);
    color: white;
}

.bad {
    background-color: var(--red);
    color: white;
}

.moved {
    transform: translate(max(-2dvw, -10px), max(-2dvw, -10px));
    transition: transform .3s ease;
}

.good.moved {
    background-color: var(--gold);
    color: white;
}

.bad.moved {
    background-color: var(--purple);
    color: white;
}
