:root {
    --white: #fff;
    --black: #000;
    --red: #dc3545;
    --green: #198754;
    --gold: #ebb734;
    --purple: #8d52d1;
    --faded: #888;
}

body {
    margin: 0;
    font-family: 'Varela Round', 'Arial';
    font-weight: bold;
    background-color: var(--background);
    color: var(--foreground);
}

.app {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;
}

.grid {
    display: grid;
    grid: repeat(3, min(20dvw, 100px)) / repeat(4, min(20dvw, 100px));
    margin: 20px 0 24px 0;
    background-color: var(--black);
    outline: 1px solid var(--foreground);
}

.input-container {
    display: flex;
    align-items: center;
}

.input {
    margin: 0;
    flex: 1;
    box-sizing: border-box;
    margin-right: min(20px, 6dvw);
}

.input, .counter {
    font-size: min(18px, 4dvw);
    padding: min(10px, 3dvw);
    outline: 1px solid var(--foreground);
    user-select: none;
}

.input.empty {
    color: var(--faded);
}

.input.won {
    background-color: var(--gold);
    color: var(--white);
}

.input.lost {
    background-color: var(--purple);
    color: var(--white);
}

.input {
    transition: background-color .4s ease;
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo, .mode {
    font-size: min(30px, 6dvw);
    user-select: none;
}

.mode > span {
    color: var(--faded);
}

.mode.daily > span {
    color: var(--gold);
}

.info-panel {
    outline: 1px solid var(--foreground);
}

.info-text {
    font-size: min(16px, 3.5dvw);
}

@media only screen and (max-width: 600px) {
    .info-text {
        font-size: min(18px, 4dvw);
    }
}

.info-guess {
    display: flex;
    align-items: center;
    padding: 10px;
    outline: 1px solid var(--foreground);
    background-color: var(--background);
}

.info-guess > p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 1px solid var(--foreground);
    background-color: var(--background);
    user-select: none;
}

.fill.neutral {
    background-color: var(--foreground);
    color: var(--background);
}

.fill.good {
    background-color: var(--green);
    color: var(--white);
}

.fill.bad {
    background-color: var(--red);
    color: var(--white);
}

.fill.win {
    background-color: var(--gold);
    color: var(--white);
}

.fill.lose {
    background-color: var(--purple);
    color: var(--white);
}

.shake {
    animation: shake-animation 0.5s ease-in-out infinite;
}

@keyframes shake-animation {
    0%, 100% {
        transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
        transform: translateX(-5px);
    }
    20%, 40%, 60%, 80% {
        transform: translateX(5px);
    }
}
