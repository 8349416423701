.keyboard {
    display: grid;
    grid: repeat(3, min(40px, 10dvw)) / repeat(10, min(40px, 10dvw));
    max-width: 400px;
    margin-top: 24px;
    padding: 0;
    background-color: var(--background);
    outline: 1px var(--foreground) solid;
}

.keyboard, .info-panel {
    max-width: 400px;
    margin-top: 24px;
}

.info-panel {
    width: 400px;
    height: 120px;
    display: grid;
    grid-template-columns: 360px 40px;
}

.info-buttons, .info-text {
    display: grid;
}

.info-text {
    grid: repeat(3, 40px) / 40px 140px 40px 140px;
}

.info-buttons {
    grid-template-rows: repeat(3, min(40px, 10dvw));
}

@media only screen and (max-width: 600px) {
  .keyboard {
      grid: repeat(3, min(60px, 10dvw)) / repeat(10, min(60px, 10dvw));
  }

  .keyboard, .info-panel {
      max-width: 600px;
  }

  .info-panel {
      width: min(600px, 100dvw);
      height: min(180px, 30dvw);
      grid-template-columns: min(540px, 90dvw) min(60px, 10dvw);
  }

  .info-text {
      grid: repeat(3, min(60px, 10dvw)) / min(60px, 10dvw) min(210px, 35dvw) min(60px, 10dvw) min(210px, 35dvw);
  }

  .info-buttons {
      grid-template-rows: repeat(3, min(60px, 10dvw));
  }
}

.key {
    background-color: var(--background);
    color: var(--foreground);
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: min(16px, 4dvw);
    outline: 1px solid var(--foreground);
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.key.active {
    background-color: var(--foreground);
    color: var(--background);
}

.key.press {
    transform: translate(0, -25%);
}

.key.invert {
    background-color: var(--foreground);
    color: var(--background);
    outline: 1px solid var(--foreground);
}

.key.active.invert {
    background: var(--purple);
    color: var(--white);
}

.key.active.good {
    background-color: var(--green);
    color: var(--white);
}

.key.active.bad {
    background-color: var(--red);
    color: var(--white);
}

.key.active.warn {
    background-color: var(--gold);
    color: var(--white);
}

